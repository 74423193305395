import { BrowserRouter, Routes, Route } from "react-router-dom";

import React from 'react'
import App from "./Main";
import Success from "./Success";
import Main from "./Main";
import GenerateToken from "./GenerateToken";

const RootRouter = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/success" element={<Success />} />
      <Route path="/generateToken" element={<GenerateToken />} />

    </Routes>
    </BrowserRouter>
  )
}

export default RootRouter