import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode';

const GenerateToken = () => {
    const [token, setToken] = useState();
    const [finalToken, setFinalToken] = useState()
    const [decodedDatas,setDecodedDatas] = useState()

    const generate = () => {
        fetch(`https://payment-b.ethical-digit.com/checkout/testingtoken`, {
            method: "GET",
            headers: {
              "Content-Type": 'Application/JSON',
            },
         })
            .then((response) => response.json())
            .then(json => {
              setToken(json?.token)
            })
            .catch(e => console.log(e))
    }

    useEffect(() => {
        if(token) {
             fetch(`https://payment-b.ethical-digit.com/checkout/initial`, {
            method: "POST",
            headers: {
              "Content-Type": 'Application/JSON',
              'merchantID': '6482cdd965d2dc69bfc3335e'
            },
            body: JSON.stringify({'paydata': token})
         })
            .then((response) => response.json())
            .then(json => {
                console.log(json?.data)
                setFinalToken(json?.data)
            })
            .catch(e => console.log(e))
        }
       
    },[token])

    useEffect(() => {
    if(finalToken) {
        const decodedToken = jwtDecode(finalToken, 'b9151f074c512dbf12d51d1a144fac433ea82b83a27665e673757ae909ae627f');
        console.log(decodedToken);
        setDecodedDatas(decodedToken);
    }
        
    
      }, [finalToken]);
  return (
    <div>
        <button onClick={generate}>Generate testing Token</button>
        <p><a href={decodedDatas?.url}>{decodedDatas?.url}</a></p>
    </div>
  )
}

export default GenerateToken