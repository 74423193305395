import React, { useEffect, useState } from 'react'
import logo from './logo.png'
import './App.css'
import { Button } from 'reactstrap'

import datas from "./data";

const Success = () => {
  const [redirectURl, setRedirectURL]  = useState('')
  const [changeLanguage, setChangeLanguage] = useState(localStorage.getItem('changeLanguage') || 'false')
  const [data, setdata] = useState()

  console.log(changeLanguage)
  console.log(data);

  const fetchExactData = (one) => {
    console.log(typeof(changeLanguage) );
    if (changeLanguage == 'false') {
      one.map((languages) => {
        setdata(languages.english);
      });
    }
    if (changeLanguage == 'true') {
      one.map((languages) => {
        setdata(languages.myanmar);
      });
    }
  };
  // setInterval(fetchExactData(datas), 1000);
  setTimeout(() => {
    fetchExactData(datas)
  }, 0);
    

  useEffect(() => {
    setRedirectURL(localStorage.getItem('callbackurl'));
  }, []);

  const preback = () => {
      window.history.forward()
    }
    setTimeout(() => {
      preback();
    }, 0);

    useEffect(() => {
      const handlePopState = (event) => {
        // Handle the popstate event here
        console.log('Popstate event occurred', event);
      };
  
      // Add the event listener when the component mounts
      window.addEventListener('popstate', handlePopState);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, []); 
  const redirect = () => {
    window.open('', '_self').location.href = redirectURl;
  }

  return (
    <div className="App container pt-4">
      <img style={{width: '70px'}} className='col-lg-2 col-6 text-start' src={logo} alt="" />
      <div className='successParent p-2'>
        <div className='successDiv mt-lg-3'>
        <p className='successTitle mb-0 mt-2'>{data?.paymentSuccessful}</p>
        <p className='successNoti mb-0'>{data?.paymentSuccessfulone} </p>
        <div className='text-center'>
             <img className='m-auto' style={{width: '162px'}} src={`${window.location.href.replace("/success", "")}/one.gif`} alt="" />
        <br /><Button onClick={() => redirect()} className='m-auto btnstyle mb-3'>{data?.backtoHome}</Button>
        </div>
       
      </div>
      </div>
      
    </div>
  )
}

export default Success